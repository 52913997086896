
.chart-table-page {
    // background: blue;
    .title-chart-wrap {
        width: 100%;
        height: 230px;
        // background: url(../assets/img/chartbg.png);
        // background-size: 100% 100%;

        // background-image: url(../assets/img/chart.jpg), url(../assets/img/chartbg.png);
        // background-repeat: no-repeat, no-repeat;
        // background-position: 0 0, 0 15px;
        // background-size: 100% 100%, 100% 87px;

        background-image: url(../assets/img/chartbg.png),url(../assets/img/chart.jpg);
        background-repeat: no-repeat, no-repeat;
        background-position: 0 15px, 0 0;
        background-size: 100% 87px, 100% 100%;

        // background-image: url(../assets/img/chart.jpg);
        // background-repeat: no-repeat;
        // background-position: 0 0;
        // background-size: 100% 100%;



        .title-chart {
            h5 {
                height: 44px;
                font-size: 44px;
                font-weight: 400;
                color: #fefefe;
                padding-top: 37px;
                padding-left: 19%;
                // 30px;
            }
            div {
                padding-top: 10px;
                input {
                    width: 667px;
                    height: 56px;
                    background: #ffffff;
                    border: 1px solid #e6e6e6;
                    border-radius: 4px;
                    font-size: 16px;
                    box-sizing: border-box;
                    padding-left: 16px;
                    margin-top: 23px;
                    margin-left: 19%;
                }
            }
        }
    }

    .chart-content {
        display: flex;
        justify-content: space-between;
        .left-chart-wrap {
            border: 1px solid #f5f5f5;
            box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
            box-sizing: border-box;
            padding: 0 20px;
            margin-top: 24px;
            margin-bottom: 34px;
            ul {
                li {
                    border-bottom: 1px solid #e6e6e6;
                    margin-bottom: 9px;
                    h5.title-chart {
                        width: 820px;
                        color: #333333;
                        font-size: 18px;
                        margin: 22px 0 17px;
                        line-height: 18px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        cursor: pointer;
                    }
                    .com-style {
                        display: flex;
                        p {
                            font-size: 14px;
                            span {
                                font-size: 14px;
                            }
                        }
                        p:first-child {
                            margin-right: 40px;
                            color: #333;
                            span {
                                color: #666666;
                            }
                        }
                    }
                    .belong {
                        margin-bottom: 12px;
                    }
                    .author-date {
                        margin-bottom: 20px;
                    }
                }
                li:last-child {
                    margin-bottom: 22px;
                }
            }
        }

        .right-download-wrap {
            width: 316px;
            padding: 0 16px;
            margin-top: 24px;
            box-sizing: border-box;
            border: 1px solid #f5f5f5;
            box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
            .com-title {
                height: 45px;
                line-height: 45px;
                border-bottom: 2px solid #dedede;
                display: flex;
                align-items: center;
                justify-content: space-between;
                div {
                    display: flex;
                    align-items: center;

                    img {
                        width: 24px;
                        height: 24px;
                        margin-right: 11px;
                        margin-left: 3px;
                    }
                    h5 {
                        height: 45px;
                        line-height: 45px;
                        font-size: 24px;
                        color: #000000;
                        border-bottom: 3px solid #bd1a2d;
                    }
                }

                ul {
                    display: flex;
                    li {
                        font-size: 18px;
                        margin-right: 18px;
                        cursor: pointer;
                        line-height: 36px;
                        color: #333;
                    }
                }
                span {
                    font-size: 14px;
                    color: #404040;
                    cursor: pointer;
                    img {
                        width: 4px;
                        height: 8px;
                        margin-left: 10px;
                    }
                }
            }
            ul.right-download {
                .first-download {
                    display: flex;
                    margin-top: 14px;
                    div {
                        img {
                            width: 118px;
                            height: 74px;
                            margin-right: 9px;
                            margin-bottom: 15px;
                        }
                        h5 {
                            width: 155px;
                            font-size: 16px;
                            color: #333333;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            margin-bottom: 10px;
                        }
                        p {
                            width: 155px;
                            font-size: 14px;
                            color: #666666;
                            text-overflow: -o-ellipsis-lastline;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            line-clamp: 2;
                            -webkit-box-orient: vertical;
                        }
                    }
                }
                .download-li {
                    width: 282px;
                    box-sizing: border-box;
                    line-height: 17px;
                    margin: 0 auto 18px;
                    font-size: 16px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    padding-left: 10px;
                    display: inline-block;
                    position: relative;
                    img {
                        width: 19px;
                        height: 19px;
                        margin-right: 4px;
                        vertical-align: middle;
                    }
                }
                .download-li::before {
                    content: '';
                    width: 4px;
                    height: 4px;
                    border-radius: 50%;
                    background: #ffac13;
                    position: absolute;
                    left: 0;
                    top: 8px;
                }
            }
        }
    }
}
